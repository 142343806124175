// import Auth from 'Layout/Auth';
import NotFound from 'components/notfound/NotFound';
import Bakery from './../Layout/Bakery';
import AboutMaster from './../Page/About/AboutMaster';
import Home from './../Page/Home/Home';
import Services from './../Page/services/Services';
import ConnectHome from './../Page/Home/Connect/ConnectHome';
import Client from './../Page/Client/Client';
import ProjectMaster from './../Page/Project/ProjectMaster';

const Component = { 
    Bakery,
    NotFound,
    Home,
    Services,
    AboutMaster,
    Client,
    ProjectMaster,
    ConnectHome

}

export default Component




