import { ReactComponent as LangOutline } from 'assets/svg/Lang Icons.svg';
import { ReactComponent as ArrowMore } from 'assets/svg/arrow.svg';
import { ReactComponent as ArrowLg } from 'assets/svg/arrowLg.svg';

import { ReactComponent as Map } from 'assets/svg/map.svg';
import { ReactComponent as Whatsapp } from 'assets/svg/whatsapp.svg';
import { ReactComponent as Email } from 'assets/svg/email.svg';
import { ReactComponent as Call } from 'assets/svg/Call outline.svg';
import { ReactComponent as Home } from 'assets/svg/Home Outline.svg';

import { ReactComponent as Food1 } from 'assets/svg/food1.svg';
import { ReactComponent as Food2 } from 'assets/svg/food2.svg';
import { ReactComponent as Food3 } from 'assets/svg/food3.svg';
import { ReactComponent as Food4 } from 'assets/svg/food4.svg';

import { ReactComponent as Service1 } from 'assets/svg/service1.svg';
import { ReactComponent as Service2 } from 'assets/svg/service2.svg';
import { ReactComponent as Service3 } from 'assets/svg/service3.svg';
import { ReactComponent as Service4 } from 'assets/svg/service4.svg';
import { ReactComponent as BgContact } from 'assets/svg/bgContact.svg';
import { ReactComponent as Aboutus } from 'assets/svg/aboutus.svg';
import Twitter from 'assets/svg/Twitter';
import Facebook from 'assets/svg/Facebook';
import Instagram from 'assets/svg/Instagram';
import Linkedin from 'assets/svg/Linkedin';
const Icons = {
    LangOutline,
    BgContact,
    ArrowMore,
    Aboutus,
    Food1,
    Twitter,
    Facebook,
    Instagram,
    Linkedin,
    Food2,
    Food3,
    Food4,
    ArrowLg,
    Map, Whatsapp,
    Email,
    Call,
    Home,
    Service1,
    Service2,
    Service3,
    Service4,
}

export default Icons;
