import { Carousel, Container } from 'react-bootstrap';
import './Services.scss';
import img from 'constants/Img';
import SliderAbout from 'Page/Home/Slider/SliderAbout';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from 'context/LangChange';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Loadering from 'components/Loadering';
const Services = () => {

    let { t } = useTranslation()
    let { isLang } = useContext(LocalizationContext);

    const url = `${process.env.REACT_APP_API_URL}/services`;
    const [Loader, setLoader] = useState(false)

    const [data, setData] = useState(null)
    const fetchData = async () => {
        setLoader(false)
        let { data } = await axios.get(url, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'application/json',
                'Accept-Language': isLang,
            }
        })
        setData(data?.data?.services);
        setTimeout(() => {
            setLoader(true)
        }, 1000);
        setLoader(false)
    }
    useEffect(() => {
        fetchData()
        window.scroll(0, 0)

    }, [isLang])
    return (

        <div className='position-relative'>
            {
                Loader === true ?
                    <>
                        <div className='app_service_master'>
                            <div className="app__services">
                                <Carousel className='app__slider overflow-hidden  ' interval={3000} slide>
                                    {
                                        data?.map((item, index) => (
                                            <Carousel.Item key={index} interval={1000} className="h-100">
                                                <img src={item?.image} className='w-100 h-100 object-fit-cover' alt="slider" />
                                                <div className="overlay">
                                                    <Container className=''>
                                                        <h1>{item.title}</h1>
                                                        <div className="contact_btn ">
                                                            <Link to={'/contact'}  >
                                                                <Button label={t('Make_Order')} className='mt-5' />
                                                            </Link>
                                                        </div>

                                                    </Container>
                                                </div>
                                            </Carousel.Item>
                                        ))
                                    }



                                </Carousel>
                            </div>
                            <SliderAbout data={data} />
                        </div>
                    </> :
                    <Loadering />
            }
        </div>
    )
}

export default Services
