import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './project.scss';

const Project = ({ data }) => {
    let { t } = useTranslation();

    // Check if data is undefined or has no length
    if (!data || data.length === 0) {
        return null; // or return some default content or loading indicator
    }

    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ]; 

    const productTemplate = (item) => {
        return (
            <div className="text-center">
                <div className="mb-3">
                    <img src={`${item.image}`} alt={'product'} className="w-75 object-fit-contain" style={{ borderRadius: '20px' }} />
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="app__clients">
                <h1 className='text-center text-light mb-5'>Project</h1>
                <div className="pt- pb-5  " dir='ltr'>
                    <Carousel
                        value={data}
                        numVisible={3}
                        numScroll={1}
                        responsiveOptions={responsiveOptions}
                        circular
                        autoplayInterval={3000}
                        autoplay={true}
                        itemTemplate={productTemplate} />

                </div>
                <div className="mx-auto btn_view mb-5 w-10 flex justify-content-center align-items-center">
                    <Link className='link__home_btn_sidebar' to={'/client'}>
                        <Button size='small' label={t('See More')} />
                    </Link>
                </div>
            </div>
        </>
    );
}

export default Project;
