import React, { useContext, useEffect, useState } from 'react'
import './style.scss'
import { Container, Row, Col } from 'react-bootstrap'
import img from 'constants/Img'
import { ScrollPanel } from 'primereact/scrollpanel';

import { LocalizationContext } from 'context/LangChange'
import axios from 'axios'
import Loadering from 'components/Loadering';
const ProjectMaster = () => {

    const [Loader, setLoader] = useState(false)
    let { isLang } = useContext(LocalizationContext);
    const [data, setData] = useState(null)
    const [type, setType] = useState('Videography')
    const fetchData = async () => {
        setLoader(false)

        const url = `${process.env.REACT_APP_API_URL}/projects?category=${type}`;
        let { data } = await axios.get(url, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'application/json',
                'Accept-Language': isLang,
            }
        })
        setData(data?.data);
        setTimeout(() => {
            setLoader(true)
        }, 1000);
        setLoader(false)
    }
    useEffect(() => {
        fetchData()
        window.scroll(0, 0)
    }, [isLang, type])
    return (
        <div className='position-relative'>
            {
                Loader === true ?
                    <>
                        <div className='app__project_master w-100 '>
                            <div className=" app__Our_project " /* style={{ position: 'relative', minHeight: '100vh' }} */>
                                <Container>
                                    <div className="header">
                                        <h1>{data?.section_title}</h1>
                                        <p className="mt-5">{data?.section_content}</p>
                                    </div>
                                    <nav className='mt-8 mb-8 bg_scroll'>
                                        <div className='scroll_container ' style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
                                            <span className={`${type === "Videography" && 'text-cyan-300'} cursor-pointer `} onClick={() => setType('Videography')}>Videography</span>
                                            <span className={`${type === "Photography" && 'text-cyan-300'} cursor-pointer px-6`} onClick={() => setType('Photography')}> Photography</span>
                                            <span className={`${type === "Video-Editing" && 'text-cyan-300'} cursor-pointer`} onClick={() => setType('Video-Editing')}>Video-Editing</span>
                                        </div>
                                    </nav>
                                    {
                                        data?.projects?.map((item, index) => (
                                            <Row className={`projects_detals pt-8 ${index % 2 !== 0 && 'flex-row-reverse'}  `}>
                                                <Col xl={5} lg={5} md={5} sm={12}>
                                                    <div className="image_project">
                                                        <img src={item?.image} alt="" className='w-100 shadow-lg' />
                                                        <div className="overlay">
                                                            <div className="content h-full flex gap-3 flex-column justify-content-center align-items-start pl-6">
                                                                <div className="flex justify-content-center align-items-center flex-column gap-3">
                                                                    {/*{
                                                        item.logo ?
                                                        <img src={item.logo} width={80} alt="" srcset="" />: */}
                                                                    <img src={img.Client2} width={80} alt="" srcset="" />
                                                                    {/* } */}
                                                                    <span className='text-center'>{item?.company_name}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xl={7} lg={7} md={7} sm={12} className={`project_content ${index % 2 !== 0 && 'p-0'}`}  >
                                                    <span>0{index + 1}</span>
                                                    <h2>{item?.title}</h2>
                                                    <p>{item?.description}</p>
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                </Container>
                            </div>
                            <div className="overlay"></div>
                        </div>
                    </> :
                    <Loadering />
            }

        </div>

    )
}

export default ProjectMaster
