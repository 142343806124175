import React, { useContext, useEffect, useState } from 'react';
import './style.scss'
import { Col, Container, Row } from 'react-bootstrap'
import img from 'constants/Img'
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useTranslation } from 'react-i18next';
import Loadering from 'components/Loadering';
const AboutMaster = () => {
  let { t } = useTranslation()

  let { isLang } = useContext(LocalizationContext);

  const url = `${process.env.REACT_APP_API_URL}/about-us`;
  const [Loader, setLoader] = useState(false)
  const [data, setData] = useState([])
  const fetchData = async () => {
    setLoader(false)

    let { data } = await axios.get(url, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'application/json',
        'Accept-Language': isLang,
      }
    })
    setData(data?.data?.about_us);
    setTimeout(() => {
      setLoader(true)
    }, 1000);
    setLoader(false)
  }
  useEffect(() => {
    fetchData()
    window.scroll(0, 0)

  }, [isLang])
  return (
    <>


      <div className='position-relative'>
        {
          Loader === true ?
            <>
              <div className="app_about_master ">
                <div className="contact   ">
                  <div className='app_about_master_container'>
                    <div className=" ">
                      <h1>{t('nav_about')}</h1>
                      <div className="about1 flex flex-row justify-content-center align-items-center">
                        <div className="logo">
                          <img src={img.logoAbout} alt="" srcset="" />
                        </div>
                        <div className="content content_ml" style={{ marginLeft: '-' }}>
                          <h3>{data[0]?.title}</h3>
                          <p>{data[0]?.description}</p>

                        </div>
                      </div>
                      <Row>
                        <Col xl={6} lg={6} md={6} sm={12}>
                          <div className="about1 flex flex-row justify-content-center align-items-center">
                            <div className="logo">
                              <img src={img.logoAbout} width={200} alt="" srcset="" />
                            </div>
                            <div className="content content_ml_1"  >
                              <h3>{data[1]?.title}</h3>
                              <p>{data[1]?.description}</p>
                            </div>
                          </div>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12}>
                          <div className="about1 flex flex-row justify-content-center align-items-center">
                            <div className="logo">
                              <img src={img.logoAbout} width={200} alt="" srcset="" />
                            </div>
                            <div className="content content_ml_1"  >
                              <h3>{data[2]?.title}</h3>
                              <p>{data[2]?.description}</p>

                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className=" mt-8">
                      <h1>{t('Our_values')}</h1>

                      <div className="about1 flex flex-row justify-content- align-items-center">
                        <div className="logo">
                          <img src={img.logoAbout} alt="" srcset="" />
                        </div>
                        <div className="content content_ml" >
                          <h3>{data[3]?.title}</h3>
                          <p className='w-75'>{data[3]?.description}</p>
                        </div>
                      </div>
                      <Row>

                        <Col xl={6} lg={6} md={6} sm={12} className='mt-5'>
                          <div className="about1 flex flex-row   align-items-center">
                            <div className="content" >
                              <h3>{data[4]?.title}</h3>
                              <p>{data[4]?.description}</p>
                            </div>
                          </div>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} className='mt-5'>
                          <div className="about1 flex flex-row   align-items-center">
                            <div className="content" >
                              <h3>{data[5]?.title}</h3>
                              <p>{data[5]?.description}</p>
                            </div>
                          </div>
                        </Col>

                        <Col xl={6} lg={6} md={6} sm={12} className='mt-5'>
                          <div className="about1 flex flex-row   align-items-center">
                            <div className="content" >
                              <h3>{data[6]?.title}</h3>
                              <p>{data[6]?.description}</p>
                            </div>
                          </div>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} className='mt-5'>
                          <div className="about1 flex flex-row   align-items-center">
                            <div className="content" >
                              <h3>{data[7]?.title}</h3>
                              <p>{data[7]?.description}</p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <div className="overlay"></div>
              </div>
            </> :
            <Loadering />
        }

      </div>
    </>
  )
}

export default AboutMaster
