import React, { useContext, useEffect, useState } from 'react'
import './style.scss'
import { Container, Row, Col } from 'react-bootstrap'
import img from 'constants/Img'
import { motion } from 'framer-motion';
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import Loadering from 'components/Loadering';
const Client = () => {

    let { isLang } = useContext(LocalizationContext);

    const url = `${process.env.REACT_APP_API_URL}/clients`;
    const [Loader, setLoader] = useState(false)
    const [data, setData] = useState(null)
    const fetchData = async () => {
        let { data } = await axios.get(url, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'application/json',
                'Accept-Language': isLang,
            }
        })
        setData(data?.data);
        setTimeout(() => {
            setLoader(true)
        }, 1000);
        setLoader(false)
    }
    useEffect(() => {
        fetchData()
        window.scroll(0, 0)

    }, [])

    return (
        <div className='position-relative'>

            {
                Loader === true ?
                    <div className='app__client w-100 '>
                        <div className=" app__ourClicnts mb-5" /* style={{ position: 'relative', minHeight: '100vh' }} */>

                            <Container>
                                <div className="app__clients2 ">
                                    <div className="app__clients_title pt-7 flex justify-content-center flex-column align-items-start w-100">
                                        <div className="header">
                                            <h3>{data?.section_title}  </h3>
                                        </div>
                                        <p className='w-75'>{data?.section_content}</p>
                                    </div>
                                </div>

                                <Row>
                                    {
                                        data?.clients?.map((item, index) => (
                                            <Col key={index} xl={3} lg={3} md={4} sm={4} className='d-flex flex-column  align-item-center justify-content-center mt-8'>
                                                <motion.img
                                                    style={{ cursor: 'pointer', objectFit: 'contain', objectPosition: '50% 50%', margin: 'auto' }}
                                                    src={item.image}
                                                    width={'30%'}
                                                    alt={'company.title'}
                                                    whileInView={{
                                                        scale: [0, 1],
                                                        transition: {
                                                            type: "spring",
                                                            stiffness: 260,
                                                            damping: 20,
                                                            delay: 0.5,
                                                            transition: 0.5
                                                        }
                                                    }}
                                                />
                                                <h2 className='text-center mt-3'>{item?.title}</h2>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </Container>

                        </div>
                        <div className="overlay"></div>
                    </div> :
                    <Loadering />
            }

        </div>
    )
}

export default Client
