import img from 'constants/Img'
import React from 'react'

const Loadering = () => {
    return (
        <div className='loader flex justify-content-center align-items-center'>
            <div className="Player">
                <img src={img.logo} width={250} alt="" srcset="" />
            </div>
        </div>
    )
}

export default Loadering
