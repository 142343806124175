import React from 'react';
import './about.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const About = ({ data }) => {
    let { t } = useTranslation()

    return (
        <>
            {
                data?.map((item, index) => (
                    <div className={`app__about_home`} style={{ backgroundImage: `url(${item?.image})` }}>
                        <div className="overlay">
                            <Container className='flex h-100    justify-content-center align-item-center'>
                                <Row className={`${index % 2 !== 0 && 'flex flex-row-reverse'} w-100`}>
                                    <Col lg={6} xl={6} md={12} sm={12}> </Col>
                                    <Col lg={6} xl={6} md={12} sm={12} className='mt-8'>
                                        <span className='text-light span_title'>{item?.title}</span>
                                        <p className='mt-6' >{item?.description}</p>
                                        <Link to={'/services'}>
                                            <Button label={t('know_more')} className='mt-5' />
                                        </Link>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                ))
            }



        </>
    );
};

export default About;
