import { motion } from 'framer-motion';
import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Icons from 'constants/Icons';
import img from 'constants/Img';
import { LocalizationContext } from 'context/LangChange';
import './footer.scss';
import { useTranslation } from 'react-i18next';
import { ApiContext } from 'context/FatchApi';
const Footer = () => {
  let { isLang } = useContext(LocalizationContext);
  let { settings } = useContext(ApiContext);
  const location = useLocation();



  const urlSearchParams = new URLSearchParams(window.location.search);

  useLayoutEffect(() => {
    const findCarTypeParam = urlSearchParams.get('find-car-type');
  }, []);
  const { t } = useTranslation()

  return (
    <div>
      <div className="app__footer pt-40  overflow-hidden" dir={isLang === "en" ? 'ltr' : 'rtl'}>
        <Container>
          <Row  >
            <Col xl={3} lg={3} md={6} sm={12} className='mt-4 order-md-4 order-lg-0 order-sm-4 footer_lg'>
              <div className="flex flex-column     gap-3 mt-35">

                <motion.a
                  href={settings?.android}
                  target='_blank' rel="noreferrer"
                  whileHover={{ scale: 1.2 }}
                  transition={{ duration: 0.3 }}
                >
                  <img src={img.logo_light} alt="images" width={179} />
                </motion.a>
              </div>
              <div className={`social mt-8 `}>
                <motion.a
                  href={settings?.twitter}
                  target='_blank' rel="noreferrer"
                  whileHover={{ scale: 1.2 }}
                  transition={{ duration: 0.3 }}
                >
                  <Icons.Twitter color={'#fff'} />
                </motion.a>

                <motion.a
                  href={settings?.facebook}
                  target='_blank' rel="noreferrer"
                  whileHover={{ scale: 1.2 }}
                  transition={{ duration: 0.3 }}
                >
                  <Icons.Facebook color={'#fff'} className='facebook' />
                </motion.a>

                <motion.a
                  href={settings?.instagram}
                  target='_blank' rel="noreferrer"
                  whileHover={{ scale: 1.2 }}
                  transition={{ duration: 0.3 }}
                >
                  <Icons.Instagram color={'#fff'} />
                </motion.a>

                <motion.a
                  href={settings?.linkedin}
                  target='_blank' rel="noreferrer"
                  whileHover={{ scale: 1.2 }}
                  transition={{ duration: 0.3 }}
                >
                  <Icons.Linkedin color={'#fff'} />
                </motion.a>
              </div>

            </Col>

            <Col xl={6} lg={6} md={12} sm={12} className='flex flex-row gap-sm-8'>
              <Col xl={6} lg={6} md={6} sm={6} className='mt-4  order-md-2 order-lg-2 order-sm-2' >
                <div className='footer_Links' >
                  <h1> {isLang === "en" ? 'Links' : 'روابـــط'} </h1>
                  <div className="footer_ui">
                    <ul >
                      <li> <Nav.Link as={Link} to="/" className={`nav-link ${location.pathname === '/' ? 'active active_main_color' : ''}`}>  {t('nav_home')}</Nav.Link> </li>
                      <li> <Link to={'/about'} className={`nav-link ${location.pathname === '/about' ? 'active active_main_color' : ''}`}> {t('nav_about')} </Link> </li>
                      <li> <Link to={'/services'} className={`nav-link ${location.pathname === '/services' ? 'active active_main_color' : ''}`}>{t('nav_service')}</Link>  </li>

                    </ul>
                  </div>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} className='mt-4 order-md-3 order-lg-3 order-sm-3'  >
                <div className='footer_Links' >
                  <h1> {isLang === "en" ? 'Additional links' : 'روابـــط إضافية'} </h1>
                  <div className="footer_ui">
                    <ul >

                      <li> <Link to={'/project'} className={`nav-link ${location.pathname === '/project' ? 'active active_main_color' : ''}`}>{t('nav_project')} </Link>  </li>
                      <li> <Link to={'/clients'} className={`nav-link ${location.pathname === '/clients' ? 'active active_main_color' : ''}`}>{t('nav_clients')} </Link>  </li>
                      <li> <Link to={'/contact'} className={`nav-link ${location.pathname === '/contact' ? 'active active_main_color' : ''}`}>{t('nav_connect')} </Link>  </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Col>

            <Col xl={3} lg={3} md={6} sm={12} className='mt-4 order-md-1 order-lg-1 order-sm-1 footer_lg' >
              <div className='footer_Links' >
                <h1> {isLang === "en" ? 'Support  ' : '  معلومات الاتصال'} </h1>
                <div className="footer_ui">
                  <ul >
                    <li>{isLang === "en" ? 'Saudi Arabia  ' : 'صندوق بريد 8524، الرياض 11429'}</li>
                    <li >  <a href={`mailto:${settings?.email}`} target='_blank' className='text-dark'>  {settings?.email}   </a> </li>
                    <li dir='ltr'>  <a href={`tel:${settings?.phone}`} target='_blank' >  {settings?.phone}</a>    </li>
                    <li className='payment_icons flex flex-row gap-3'>

                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
          <hr className='hr__footer' />
          <div className="footer__buttom pb-3  flex justify-content-center align-items-center">
            <span className='text-center'>Copyright © 2024, <a href="https://rightclick.sa/" target='_blank' className='rightClick  '  >RightClick</a>, All Rights Reserved</span>
          </div>
        </Container>
      </div>
    </div >
  )
}

export default Footer
