
import Component from 'constants/Component';
import FatchApi from 'context/FatchApi';
import LangChange from 'context/LangChange';
import { RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom';
import './style/App.scss';
import { Toaster } from 'react-hot-toast';

function App() {
  const root = createHashRouter([
    {
      path: '/', element: <Component.Bakery />, children: [
        { index: true, element: <Component.Home /> },
        { path: 'about', element: <Component.AboutMaster /> },
        { path: 'services', element: <Component.Services /> },
        { path: 'contact', element: <Component.ConnectHome /> },
        { path: 'project', element: <Component.ProjectMaster /> },
        { path: 'clients', element: <Component.Client /> },
        { path: '*', element: <Component.NotFound /> },
      ]
    },
  ])
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 3500,
          style: {
            fontFamily: ' Arial, Helvetica, sans-serif',
            textTransform: 'capitalize',
            zIndex: '9999',
            // background: '#000',
            // color: '#fff',
            borderRadius: '10px',
            boxShadow: '10px 10px 10px rgba(188, 188, 188, 0.16)',
            background: '#fff',
            color: '#000',
          },
        }}
        containerStyle={{
          bottom: 50
        }}
      />
      <LangChange>
        <FatchApi>
          <RouterProvider router={root} />
        </FatchApi>
      </LangChange>
    </>
  );
}

export default App;
