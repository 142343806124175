import React from 'react'

const Facebook = ({ color }) => {
    return (
        <> 
            <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.85596 7H11.356L10.856 9H6.85596V18H4.85596V9H0.855957V7H4.85596V5.128C4.85596 3.345 5.04196 2.698 5.38996 2.046C5.73097 1.40181 6.25776 0.875009 6.90196 0.534C7.55396 0.186 8.20096 0 9.98396 0C10.506 0 10.964 0.0500001 11.356 0.15V2H9.98396C8.65996 2 8.25696 2.078 7.84596 2.298C7.54196 2.46 7.31596 2.686 7.15396 2.99C6.93396 3.401 6.85596 3.804 6.85596 5.128V7Z" fill={color} />
            </svg> 
        </>
    )
}

export default Facebook