import { default as logo, default as logo_light } from "assets/Images/002.png";
import EnLang from 'assets/Images/En.png';
import About1 from 'assets/Images/about1.png';
import About2 from 'assets/Images/about2.png';
import About3 from 'assets/Images/about3.png';
import ArLang from 'assets/Images/ar.png';
import Slider from 'assets/Images/slider.png';
import Sliderabout1 from 'assets/Images/sliderabout1.png';
import Sliderabout2 from 'assets/Images/sliderabout2.png';
import Sliderabout3 from 'assets/Images/sliderabout3.png';
import Overlay from 'assets/Images/overlay.png';
import bgAbout from 'assets/Images/bgAbout.png';
import logoAbout from 'assets/Images/logo.png';
import Client1 from 'assets/Images/client (1).png';
import Client2 from 'assets/Images/client (2).png';
import Client3 from 'assets/Images/client (3).png';
import Client4 from 'assets/Images/client (4).png';
import Client5 from 'assets/Images/client (5).png';
import Client8 from 'assets/Images/client.png';
import Client6 from 'assets/Images/client (6).png';
import Client7 from 'assets/Images/client (7).png';
import Poject from 'assets/Images/Rectangle 35.png';

const img = {

    logo,
    Poject,
    logo_light,
    EnLang,
    Slider,
    ArLang,
    About1,
    Client8,
    About2,
    About3,
    logoAbout,
    Sliderabout1,
    Overlay,
    Sliderabout2,
    Sliderabout3,
    Client1,
    Client2,
    Client3,
    Client4,
    Client5,
    Client6,
    Client7,
    bgAbout
}

export default img;
