import img from 'constants/Img'
import { Button } from 'primereact/button'
import React from 'react'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'


const SliderAbout = ({ data }) => {
    let { t } = useTranslation()
    return (
        <>
            <div className="slider_about overflow-hidden ">
                <>
                    <Carousel className='app__slider-home' interval={3000} fade>
                        {
                            data?.map((item, index) => (
                                <Carousel.Item key={index} interval={1000} className="   w-100 ">
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12} className={t('padding_left')}  >
                                            <h2>{item?.short_title}</h2>
                                            <p>{item?.short_description}</p>

                                            <Link to={'/about'}>
                                                <Button label={t('know_more')} className='mt-5' />
                                            </Link>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={12} className='flex overlay_master position-relative justify-content-end align-items-end image_slider_about'  >
                                            <img src={item.file} width={500} alt="" srcset="" className='overlay_about1' />
                                            <img src={img.Overlay} alt="" width={400} className='overlay_about' />
                                        </Col>
                                    </Row>
                                </Carousel.Item>
                            ))
                        }
                    </Carousel>

                </>
            </div>


        </>
    )
}

export default SliderAbout