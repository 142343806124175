
import Icons from 'constants/Icons';

const routes = [
    {
        path: "/",
        nameEn: "Home",
        nameAr: "الرئيسية",
        icon: <i className='pi pi-home'></i>,
    },
    {
        path: "/about",
        nameEn: "about Us",
        nameAr: "نبذه عنا",
        icon: <i className='pi pi-info-circle'></i>,
    },

    {
        path: "/services",
        nameEn: "  Services",
        nameAr: "منتجاتنا",
        icon: <i className='pi pi-home'></i>,
    },
    {
        path: "/project",
        nameEn: "Project",
        nameAr: "مشاريعنا",
        icon: <i className='pi pi-bookmark'></i>,
    },
    {
        path: "/clients",
        nameEn: "Clients",
        nameAr: "عملائنا",
        icon: <i className='pi pi-users'></i>,
    },


    {
        path: "/contact",
        nameEn: "Contact Us ",
        nameAr: "تواصل معنا",
        icon: <i className='pi pi-phone'></i>,

    },



];

export default routes