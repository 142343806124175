import img from 'constants/Img';
import Carousel from 'react-bootstrap/Carousel';
import './slider.scss'
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { LocalizationContext } from 'context/LangChange';
const SliderHome = () => {
    const { isLang } = useContext(LocalizationContext)

    return (
        <div className='slider_overlay vh-100 '> 
            <Carousel className='app__slider-home' interval={3000} fade>
                <Carousel.Item interval={1000} className="   w-100 ">
                    <img src={img?.Slider} className="slider__home  w-100" alt="slider" />
                    <div className="overlay flex justify-content-center align-items-center"> </div>
                
                </Carousel.Item>
                <Carousel.Item interval={1000} className="   w-100 ">
                    <img src={img?.Slider} className="slider__home  w-100" alt="slider" />
                    <div className="overlay flex justify-content-center align-items-center"> </div>
          
                </Carousel.Item>
                <Carousel.Item interval={1000} className="   w-100 ">
                    <img src={img?.Slider} className="slider__home  w-100" alt="slider" />
                    <div className="overlay flex justify-content-center align-items-center"> </div>
             
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default SliderHome
