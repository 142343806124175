
import { LocalizationContext } from 'context/LangChange';
import { useContext, useEffect, useState } from 'react';
import About from './About/About';
import SliderAbout from './Slider/SliderAbout';
import SliderHome from './Slider/SliderHome';
import Project from './project/Project';
import axios from 'axios';
import img from 'constants/Img';
import Loadering from 'components/Loadering';
const Home = () => {
    let { isLang } = useContext(LocalizationContext);

    const url = `${process.env.REACT_APP_API_URL}/home`;
    const [Loader, setLoader] = useState(false)

    const [data, setData] = useState(null)
    const fetchData = async () => {
        setLoader(false)
        let { data } = await axios.get(url, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-Requested-With': 'application/json',
                'Accept-Language': isLang,
            }
        })
        setData(data);
        setTimeout(() => {
            setLoader(true)
        }, 1000);
        setLoader(false)
    }
    useEffect(() => {
        fetchData()
        window.scroll(0, 0) 
    }, [isLang])
    return (

        <div className='position-relative'>
            {
                Loader === true ?
                    <>
                        <SliderHome data={data?.slider} />
                        <SliderAbout data={data?.services?.services} />
                        <About data={data?.services?.services} />
                        <Project data={data?.projects?.projects} />
                    </> :
                    <Loadering />
            }
        </div>
    )
}

export default Home
